

























import { Vue, Component } from 'vue-property-decorator'
import gql from 'graphql-tag'
import { Announcement } from '@/types/announcement'

@Component
export default class extends Vue {
  private filesUrl: string[] = []

  mounted() {
    this.fetchSlideshow()
  }

  private async fetchSlideshow() {
    try {
      const width = 1
      const height = 1
      const { data } = await this.$apollo.query<{
        announcements: Array<Partial<Announcement>>
      }>({
        query: gql`
          query FetchSlideshow($group: String, $width: Int!, $height: Int!) {
            announcements(group: $group) {
              id
              name
              description
              url
              file(width: $width, height: $height) {
                signedUrl
                contentType
              }
            }
          }
        `,
        variables: {
          group: 'news',
          width: width,
          height: height,
        },
      })
      if (data) {
        this.filesUrl = data.announcements
          .map(el => el.file?.signedUrl || '')
          .filter(el => el !== '')
      }
    } catch (error) {
      // empty
    }
  }
}
